<template>
  <div class="center">
    <div class="log-in">
      <h1>Log In</h1>
      <div class="input">
        <p>
          Email
          <span class="important">*</span>
        </p>
        <el-input type="email" v-model="this.email" size="small" />
      </div>
      <div class="input">
        <p>
          Password
          <span class="important">*</span>
        </p>
        <el-input type="password" v-model="this.pwd" size="small" />
      </div>
      <p class="alert" v-if="validationFailed">
        Something went worng, please try again.
      </p>
      <el-button v-on:click="loginValidation" type="primary">
        <p v-if="!loadingValidation">LOG IN</p>
        <moon-loader
          :loading="loadingValidation"
          color="#fff"
          size="30px"
        ></moon-loader>
      </el-button>
    </div>
  </div>
</template>

<script>
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
  import { useRouter } from 'vue-router'
  import { validateLogin } from '../services/CloudFunctions'

  export default {
    name: 'Login',
    components: {
      MoonLoader,
    },
    data() {
      return {
        email: '',
        pwd: '',
        validationFailed: false,
        loadingValidation: false,
        router: useRouter(),
      }
    },
    beforeCreate() {
      var sessionHash = localStorage.getItem('sessionHash')
      var router = useRouter()
      if (sessionHash) router.push('/admin')
    },
    methods: {
      async loginValidation() {
        this.loadingValidation = true
        const login = await validateLogin({
          email: this.email,
          pwd: this.pwd,
        })
        if (!login.data.sessionHash) {
          this.validationFailed = true
          this.loadingValidation = false
        } else {
          localStorage.setItem('sessionHash', login.data.sessionHash)
          this.loadingValidation = false
          this.router.push('/admin')
        }
      },
    },
  }
</script>

<style scoped>
  input {
    text-transform: none !important;
  }

  p.alert {
    margin: 0 0 10px;
  }
  h1 {
    text-transform: uppercase;
    font-size: 24px;
    margin: 0 0 25px;
  }

  div.center {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.log-in {
    width: 540px;
    height: 340px;
    position: relative;
    box-sizing: border-box;
    padding: 30px 45px 45px;
    border-radius: 5px;
    background-color: #d9d9d9;
    margin: 0 0 150px;
  }

  div.input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px;
  }

  div.log-in span.important {
    color: red;
  }

  button {
    margin: 0 0 0 auto;
  }
</style>
